<!--
 * @Author: your name
 * @Date: 2021-12-13 20:22:52
 * @LastEditTime: 2021-12-24 15:29:37
 * @LastEditors: Please set LastEditors
 * @Description:
 * @FilePath: \new-website-1123\src\views\special\uva\page5.vue
-->
<template>
  <div class="uva-page5 pt80">
    <div class="title-div">
      <span class="t-span">
        支持多行业无人机巡检、巡逻业务场景
        <!-- <span>支持多行业无人机巡检</span>
        <span class="f-span">、</span>
        <span>巡逻业务场景</span> -->
      </span>
      <p class="desc-p">依托自研的自动驾驶、自动机场、远程智能控制技术</p>
      <p class="desc-p">
        为多个行业提供专业化无人机全自动巡检、巡逻、应急解决方案
      </p>
    </div>
    <div class="img-div">
      <img
        src="https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/resources/special/uva/8.png"
        alt="上太科技"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  components: {},
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang='scss' scoped>
.uva-page5 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 30px;
  .img-div {
    margin-top: 50px;
    width: 1100px;
    img {
      width: 100%;
    }
  }
}
.title-div {
  span {
    font-size: 85px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: $color-active;
    line-height: 140px;
  }
  .desc-p {
    font-size: 24px;
    font-family: PingFang SC;
    font-weight: 300;
    color: rgba($color: #fff, $alpha: 0.6);
    line-height: 48px;
    text-align: center;
  }
}
@media screen and (max-width: 1580px) {
  .title-div {
    span {
      font-size: 60px;
    }
  }
}

@media screen and (max-width: $mobile-width) {
  .title-div {
    .t-span {
      display: flex;
      flex-direction: column;
      text-align: center;
      width: 86%;
      font-size: 0.55rem;
      line-height: 0.9rem;
      margin: auto;
      .f-span {
        display: none;
      }
    }
    .desc-p {
      font-size: 0.27rem;
      width: 86%;
      margin: auto;
      line-height: 0.44rem;
    }
  }
  .uva-page5 {
    width: 100%;
    overflow: hidden;
    .img-div {
      width: 86%;
      margin: auto;
      margin-top: 30px;
      img {
        width: 100%;
      }
    }
  }
}
</style>
