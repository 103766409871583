<!--
 * @Author: your name
 * @Date: 2021-12-13 19:52:28
 * @LastEditTime: 2022-01-26 18:24:17
 * @LastEditors: Please set LastEditors
 * @Description:
 * @FilePath: \new-website-1123\src\views\special\uva\page1.vue
-->
<template>
  <div class="uva-page1 pt80">
    <div class="title-div">
      <d-title model="巡检无人机" style="margin-top: 40px"></d-title>
    </div>
    <div class="img-div">
      <!-- src="https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/resources/special/uva/1.png" -->
      <img
        src="https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/resources/special/uva/1.png"
        alt="上太科技"
      />
    </div>

    <!-- 蒙层 -->
    <div class="filter-div"></div>

    <div class="text-div">
      <span class="label-span">实时感知·智行无忧</span>
      <div class="desc-div">
        <span>手持一体显示遥控器，采用安卓系统，搭载7寸高清屏幕;</span>
        <span>高达14个控制通道设置;可收放式脚架，</span>
        <span>实时1080P数字高清图像传输接收;远程距离高度 5km，</span>
        <span
          >支持飞空系统数据传输;实时回传飞行器情况，支持摄像机 HDMI网口端</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import dTitle from "@/components/sTitle/title";
export default {
  name: "",
  components: {
    dTitle,
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.uva-page1 {
  position: relative;
  height: 100vh;
  overflow: hidden;
  .img-div {
    position: absolute;
    top: 0;
    width: 80%;
    height: 100%;
    left: 11%;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .filter-div {
    position: absolute;
    opacity: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color: #000, $alpha: 0.8);
  }
  .text-div {
    position: absolute;
    opacity: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .label-span {
      color: $color-active;
      font-size: 85px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      transform: translateY(100%);
    }
    .desc-div {
      opacity: 0;
      transform: translateY(-40%);
      margin-top: 30px;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 24px;
      font-family: PingFang SC;
      font-weight: 400;
      color: rgba($color: #fff, $alpha: 0.7);
      line-height: 40px;
    }
  }
}

@media screen and (max-width: $mobile-width) {
  .uva-page1 {
    height: auto;
    .title-div {
      margin-top: 40px;
    }
    .img-div {
      position: relative;
      top: 0;
      width: 80%;
      height: 100%;
      left: 11%;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .filter-div {
      display: none;
    }
    .text-div {
      position: relative;
      opacity: 1;
      height: 5.45rem;
      width: 86%;
      margin: auto;
      background-image: url("https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/resources/special/uva/1.png");
      background-size: contain;
      .label-span {
        font-size: 0.55rem;
        transform: translateY(0);
      }
      .desc-div {
        opacity: 1;
        transform: translateY(0);
        margin-top: 0.27rem;
        font-size: 0.27rem;
        line-height: 0.36rem;
        text-align: center;
        display: block;
      }
    }
  }
}
</style>
