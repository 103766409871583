<!--
 * @Author: your name
 * @Date: 2021-12-13 19:50:47
 * @LastEditTime: 2021-12-24 14:43:37
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \new-website-1123\src\views\special\uva\index.vue
-->
<template>
  <div class="uva-page">
    <div class="sticky-box1">
      <div class="sticky-child">
        <page1></page1>
      </div>
    </div>
    <div></div>
    <page2 class="opacity-page scale-page"></page2>
    <page3 class="opacity-page scale-page"></page3>
    <page4 class="opacity-page">
      <img
        slot="img"
        src="https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/resources/special/uva/5.png"
        alt="上太科技"
      />
      <div class="content-div" style="transform: translateX(-15%)">
        <span class="title-span">智能飞控</span>
        <p>
          全自主作业，自主规划航线；只需在App上预设基本飞行及喷洒参数，便可在平原、丘陵、树林等不同场景自如作业。
        </p>
      </div>
    </page4>
    <page4 :img-order="3" class="opacity-page">
      <img
        slot="img"
        src="https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/resources/special/uva/6.png"
        alt="上太科技"
      />
      <div class="content-div">
        <span class="title-span">智能感知</span>
        <p>
          搭载前沿的行业级飞控系统，精准导航，不差毫厘；拥有全向感知能力，自动绕行农田障碍物；多重冗余系统，AI
          故障预判，保证飞行安全
        </p>
      </div>
    </page4>

    <page4 class="opacity-page">
      <img
        slot="img"
        src="https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/resources/special/uva/7.png"
        alt="上太科技"
      />
      <div class="content-div" style="padding-left: 20px">
        <span class="title-span">智能多种操控模式</span>
        <span class="title-span">灵活协同作业</span>
        <p>
          手持一体显示遥控器，搭载7寸高清屏幕;高达14个控制通道设置；同时兼容安卓系统，直接通过手机
          App 便可执行全自主植保作业
        </p>
      </div>
    </page4>
    <page-5 class="opacity-page"></page-5>
  </div>
</template>

<script>
import page1 from "./page1.vue";
import page2 from "./page2.vue";
import page3 from "./page3.vue";
import page4 from "./page4.vue";
import page5 from "./page5.vue";
import {getNesViewApi} from '@/api/index.js'
export default {
  name: "",
  components: {
    page1,
    page2,
    page3,
    page4,
    page5,
  },
  data() {
    return {
      scrollTop: 0,
    };
  },
  created() {},
  mounted() {
    let html = document.querySelector("html");
    this.$nextTick(() => {
      html.scrollTop = 0;
    });
    window.addEventListener("scroll", this.cScroll, false);
    this.addView()

  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.cScroll, false);
  },
  methods: {
    addView(){
      getNesViewApi({type:1,pid:"055d6c3e66ed428caeaf533b2c22ecf4"})
    },
    /**
     * @description: 监听滚动事件
     */
    cScroll() {
      let html = document.querySelector("html");
      this.scrollTop = html.scrollTop;
      if (window.innerWidth > 800) {
        this.page1s();
      }
    },
    /**
     * @description: 第一页效果
     */
    page1s() {
      let div = document.querySelector(".uva-page1");
      let title = div.querySelector(".title-div");
      let filterDiv = div.querySelector(".filter-div");
      let text = div.querySelector(".text-div");
      let label = text.querySelector(".label-span");
      let desc = text.querySelector(".desc-div");
      if (this.scrollTop > 1400) {
        let top = this.scrollTop - 1400;
        let rate = Math.min(1, top / 500);
        text.style.transform = `scale(1)`;
        text.style.opacity = 1;
        filterDiv.style.opacity = 0.5 + rate / 2;
        label.style.transform = `translateY(${(1 - rate) * 100}%)`;
        desc.style.transform = `translateY(-${(1 - rate) * 40}%)`;
        desc.style.opacity = rate;
      } else if (this.scrollTop > 900) {
        desc.style.opacity = 0;
        let top = this.scrollTop - 900;
        filterDiv.style.opacity = 0.5;
        let rate = top / 500;
        let scale = Math.max(1, (1 - rate) * 4);
        text.style.transform = `scale(${scale})`;
        text.style.opacity = rate;
      } else if (this.scrollTop > 600) {
        let top = this.scrollTop - 600;
        title.style.opacity = 0;
        let rate = top / 300 / 2;
        text.style.opacity = 0;
        filterDiv.style.opacity = rate;
      } else if (this.scrollTop > 200) {
        let top = this.scrollTop - 200;
        let rate = top / 400;
        title.style.opacity = 1 - rate;
      } else if (this.scrollTop < 200) {
        title.style.opacity = 1;
      }
    },
  },
};
</script>

<style lang='scss' scoped>
.sticky-box1 {
  height: calc(100vh + 2400px);
  .sticky-child {
    position: sticky;
    top: 0;
  }
}
@media screen and (max-width: $mobile-width) {
  .sticky-box1 {
    height: auto;
    .sticky-child {
      position: relative;
      top: 0;
    }
  }
}
</style>
