<!--
 * @Author: your name
 * @Date: 2021-12-13 20:04:43
 * @LastEditTime: 2021-12-24 15:14:50
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \new-website-1123\src\views\special\uva\page2.vue
-->
<template>
  <div class="uva-page2">
    <img src="https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/resources/special/uva/3.jpg" alt="上太科技" />
    <ul class="c-ul">
      <li>智能飞控</li>
      <li>智能感知</li>
      <li>智能操控</li>
      <li>多场景支持</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang='scss' scoped>
.uva-page2 {
  position: relative;
  img {
    width: 100%;
    display: block;
  }
  .c-ul {
    position: absolute;
    right: 11%;
    bottom: 11%;
    font-size: 60px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: $color-active;
    line-height: 140px;
    text-align: right;
  }
}
@media screen and (max-width: $mobile-width) {
  .uva-page2 {
    position: relative;
    height: 10rem;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    img {
      height: 90%;
      width: unset;
      object-fit: cover;
      display: block;
    }
    .c-ul {
      position: absolute;
      right: unset;
      left: 50%;
      transform: translateX(-50%);
      bottom: 0.7rem;
      font-size: 0.55rem;
      line-height: 0.91rem;
      text-align: center;
    }
  }
}
</style>
